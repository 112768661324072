<template lang="">
    <div class="product">
        <div class="product-image">
            <img src="https://mykaleidoscope.ru/x/uploads/posts/2023-12/1703343947_mykaleidoscope-ru-p-pitstsa-pepperoni-doma-instagram-2.png">
        </div>
        <div class="product-title">Пицца пеперони на тонком тесте</div>
        <div class="product-description">Отведайте великолепный вкус итальянской классики с нашей пиццей Пеперони! Нежное тесто, обильное слоение аппетитного томатного соуса, пикантная пепперони и обжаренные до золотистости сырные нотки создают идеальное сочетание, которое порадует даже самых изысканных гурманов. Попробуйте нашу пиццу Пеперони и окунитесь в атмосферу настоящей итальянской кухни прямо у себя дома!</div>
        <div class="product-properties">
            <MainList />
        </div>
        
    </div>
</template>
<script>
export default {

    data() {
        return {
            tg: null,
        }
    },


    methods: {
        
    },


    beforeMount() {
        
    },
    
    mounted() {
        
    },
}
</script>
<style scoped>
    .product {

    }

    .product-image {
        
    }
        .product-image img {
            max-width: 100%;
            border-radius: 10px;
        }

    .product-title {
        font-size:16px;
        margin:10px;

    }

    .product-description {
        color: var(--tg-theme-hint-color);
        line-height: 18px;
        margin:10px;
    }

    .product-properties {
        margin:10px;
    }

</style>