<template lang="">
    <div class="home">
        
        <TopBar />



        <div class="category">
            <div class="title">🍕 Пиццы</div>
            <div class="description">
                Учитывая ключевые сценарии поведения, экономическая повестка сегодняшнего дня играет определяющее значение для первоочередных требований. 
            </div>
        </div>

        <div class="catalog">
            <ProductCard v-for="product in products" :key="product.id" :product="product" />
        </div>
        
    </div>

    <MainNav />
</template>
<script>

export default {
    
    data() {
        return {
            products: [
                {
                    id: 1,
                    name: 'Пицца пеперони Вот вам яркий пример современных',
                    //excerpt: 'Вот вам яркий пример современных тенденций — постоянный количественный',
                    price: '340 $',
                },
                {
                    id: 2,
                    name: 'Бургер с говядиной',
                    old_price: '2 800 $',
                    price: '1 045 $',
                },

                {
                    id: 3,
                    name: 'Бургер с говядиной',
                    
                    price: '360 - 560 $',
                },
            ]
        }
    },

    mounted() {
        window.Telegram.WebApp.BackButton.hide();
        window.Telegram.WebApp.MainButton.hide();


        function outputTextRepeatedly(repetitions) {
            let count = 0;
            const interval = setInterval(() => {
                window.Telegram.WebApp.HapticFeedback.notificationOccurred('success');
                count++;
                if (count === repetitions) {
                    clearInterval(interval);
                }
            }, 50);
        
            setTimeout(() => {
                clearInterval(interval);
            }, 1000);
        }
        
        outputTextRepeatedly(20);

    },
}
</script>
<style scoped>
    .home {
        margin: 0 10px 53px 10px;
    }

    .reception {
        background: var(--tg-theme-secondary-bg-color);
        height: 30px;
    }

    .category {

    }

    .category>.title {
        font-size:16px;
        font-weight:bold;
        padding:10px 0;
        /* color: var(--tg-theme-section-header-text-color); */
    }
    .category>.description {
        color: var(--tg-theme-hint-color);
        padding-bottom:10px;
        line-height: 20px;
    }

    .catalog {
        display: flex;
        margin: 0 -5px;
        align-items: stretch;
        flex-wrap: wrap;
        
    }

    .catalog>div {
        max-width: calc(50% - 10px);
        margin:5px;
        margin-bottom:25px;
    }

    


</style>