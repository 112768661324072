import { createApp } from 'vue'
import App from './App.vue'
import {Vue3ProgressPlugin} from '@marcoschulte/vue3-progress';
import router from "@/router/router";
import store from "@/store";




import components from "@/components";

const app = createApp(App);



components.forEach(elem => {
	app.component(elem.name, elem)
})


app
    .use(router)
    .use(store)
    .use(Vue3ProgressPlugin)
    .mount('#app')
