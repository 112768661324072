<template lang="">
    <div class="product">
        <div class="product-image">
            <picture>
                <img src="https://chatfood.ru/web/uploads/2022/09/khtcyS9fBMyVkafmbhLS4QzWXXH2K_.jpeg">
            </picture> 
        </div>
        <div class="product-title">
            {{product.name}}
        </div>
        <div class="product-tags">
            <span style="background:#FF3939; color:#fff">Новинка</span>
            <span style="background:#F5B04D; color:#fff">Хит</span>
            <span style="background:#4BB14B; color:#fff">Выбор шефа</span>
        </div>
        <div class="product-hint">
            250гр &#8226; 300ккал
        </div>
        <div v-if="product.excerpt" class="product-excerpt">
            {{product.excerpt}}
        </div>
        <div @click="increase()" v-if="!quantity" class="product-price">
            <div class="currency">{{product.price}}</div>
            <div v-if="product.old_price" class="old">{{product.old_price}}</div>
        </div>

        <div v-if="quantity" class="product-quantity">
            <div class="btn" @click="reduce()">-</div>
            <div class="quantity">{{quantity}}</div>
            <div class="btn" @click="increase()">+</div>
        </div>
      
    </div>
</template>
<script>

export default {
    name: 'ProductCard',

    props: {
        product: Object,
    },


    data() {
        return {
            quantity: 0,
        }
    },


    methods: {
        increase() {
            this.quantity += 1;
            this.updateStore();
        },

        reduce() {
            this.quantity -= 1;
            this.updateStore();
        },

        updateStore() {
            this.$store.commit('setBasketQuantity', this.quantity);
        }



    },


    mounted() {
       
    },

}
</script>
<style scoped>
    .product {
        width:100%;
        height: auto;
    }

    .product-image {

    }

    .product-image picture {
        display: block;
        width: 100%;
        height: 0;
        padding-top: 100%; /* Создает квадратное соотношение сторон */
        overflow: hidden; /* Обрезает изображение до размеров родителя */
        position: relative; /* Для позиционирования img */
    }

    .product-image picture img {
        position: absolute; /* Позволяет изображению занимать всю площадь родителя */
        width: 100%;
        height: 100%;
        object-fit: cover; /* Заполняет родителя, сохраняя соотношение сторон */
        top: 0;
        left: 0;
        border-radius: 10px; /* Закругление углов */
    }

    .product-title {
        padding:8px 0;
    }

    .product-hint {
        color: var(--tg-theme-hint-color);
        font-size:12px;
        padding:4px 0;
    }
    .product-excerpt {
        padding:4px 0;
        color: var(--tg-theme-hint-color);
    }

    .product-tags {
        font-size:11px;
        display: flex;
        padding:4px 0;
    }
    .product-tags>span {
        border-radius:3px;
        padding:3px;
        display:block;
        margin-right:3px;
        margin-bottom:3px;
    }

    
    
    .product-price {
        display: flex;
        margin-top: 10px;
        align-self: flex-end;
    }

    .product-price>div {
        display: flex;
        /* justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 1000px;
        margin: 0 auto; */
    }

    .product-price .currency {
        background: var(--tg-theme-button-color);
        padding:5px 15px;
        line-height: 26px;
        height:26px;
        border-radius:40px;
        cursor: pointer;
    }

    .product-price .old {
        padding:5px 15px;
        line-height: 26px;
        color: var(--tg-theme-hint-color);
        text-decoration: line-through;
        align-items: center;
    }

    .product-quantity {
        display: flex;
        margin-top: 10px;
    }

    .product-quantity .btn,
    .product-quantity .quantity {
        padding: 5px;
        line-height: 26px;
        height: 26px;
        width: 33.333333%;
        border-radius: 40px;
        
        text-align: center;
    }
    .product-quantity .btn {
        background: var(--tg-theme-button-color);
        cursor: pointer;
        font-weight: bold;
    }

   
</style>