<template>
  
    <router-view></router-view>

  
</template>

<script>

export default {
  name: 'App',
  mounted() {
    
    const tg = window.Telegram.WebApp;

    // tg.MainButton.isVisible = true;
    // tg.MainButton.text = true;
    

    tg.SettingsButton.isVisible = true;
    
  },
}
</script>

<style>

/* :root {
  --tg-theme-accent-text-color: #2ea6ff;
  --tg-theme-bg-color: #18222d;
  --tg-theme-button-color: #2ea6ff;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-destructive-text-color: #ef5b5b;
  --tg-theme-header-bg-color: #131415;
  --tg-theme-hint-color: #b1c3d5;
  --tg-theme-link-color: #62bcf9;
  --tg-theme-secondary-bg-color: #131415;
  --tg-theme-section-bg-color: #18222d;
  --tg-theme-section-header-text-color: #b1c3d5;
  --tg-theme-subtitle-text-color: #b1c3d5;
  --tg-theme-text-color: #ffffff;
  --tg-viewport-height: 100vh;
  --tg-viewport-stable-height: 100vh;
} */

/* Скрыть только вертикальный скроллбар */

/* Скрыть горизонтальный скроллбар */
body {
    overflow-x: hidden; /* скрыть горизонтальный скроллбар */
    overflow-y: none; /* отображать вертикальный скроллбар всегда */
    scrollbar-width: hidden; /* скрыть стандартный скроллбар в Firefox */
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
}
body::-webkit-scrollbar { width: 0; }
body, html {
  margin: 0;
  padding: 0;
}

 html {
  background-color: var(--tg-theme-secondary-bg-color);
  color: var(--tg-theme-text-color);
  font-family: "Segoe UI", Arial, sans-serif;
  font-size: 14px;
  
}

#app {
  
} 

a {
  color: var(--tg-theme-link-color);
}

.btn {
    width: 100%;
    border:none;
    border-radius: 10px;
    line-height: 30px;
    background: var(--tg-theme-secondary-bg-color);
}

.btn svg {
    width: 16px;
    height: 16px;
}

.btn-destructive {
    color: var(--tg-theme-destructive-text-color);
}
.btn-destructive svg {
    fill: var(--tg-theme-destructive-text-color);
}
.btn-default {
    color: var(--tg-theme-link-color);
}

</style>