<template lang="">
    <div>
        my addresses
    </div>
</template>
<script>
export default {
}
</script>
<style lang="">
    
</style>