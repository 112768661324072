<template lang="">
        <input type="text" class="" :placeholder="placeholder">
</template>
<script>
export default {
    name: 'InputForm',

    props: {
        placeholder: String,
    },
}
</script>
<style scoped>
    input {
        border: 1px solid var(--tg-theme-secondary-bg-color);
        background: var(--tg-theme-secondary-bg-color);
        width: calc(100% - 10px);
        border-radius: 10px;
        height: 40px;
        color: var(--tg-theme-text-color);
        outline: none; 
        padding: 0 4px;
        transition: text-align 0.3s ease; /* Добавлено свойство transition */
    }
    
    input:focus {
        text-align: left;
    }

    input.is-invalid {
        border: 1px solid var(--tg-theme-destructive-text-color);
        color: var(--tg-theme-destructive-text-color);
    }

    
</style>