<template lang="">

    <div class="avatar">
        <img src="https://chatfood.ru/web/uploads/2024/03/axw4YNx5LzdS4Qjnb2kS8qOkJFxs90.jpg">
        <div class="name">Николай Каспор</div>
        <div class="username">+7 967 760 14 70 &#8226; @this_nicco</div>
    </div>

    <div class="profile">
        
       
        

        <MainList />

        
    </div>
</template>
<script>
export default {

    data() {
        return {
            
        }
    },


    beforeMount() {
        
    },
    
    mounted() {
        const self = this;
        window.Telegram.WebApp.BackButton.show();
        window.Telegram.WebApp.BackButton.onClick(function() {
            self.$router.push({name: 'home'})
        });
    },
}
</script>
<style scoped>
    .profile {
        margin: 10px;
    }

    .avatar {
        text-align: center;
        background: var(--tg-theme-section-bg-color);
        padding: 10px 0;
    }

    .avatar img {
        max-width: 25%;
        border-radius:50%;
    }

    .avatar .name {
        font-size: 26px;
        font-weight: 600;
        margin-top: 5px;
    }
    .avatar .username {
        margin-top: 5px;
        color: var(--tg-theme-hint-color)
    }
</style>