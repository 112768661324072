import {createRouter, createWebHistory} from "vue-router";


import HomePage from "@/views/HomePage";
import ProductPage from "@/views/ProductPage";
import ProfilePage from "@/views/ProfilePage";
import BasketPage from "@/views/BasketPage";
import AddressesPage from "@/views/AddressesPage";
import StaticPage from "@/views/StaticPage";

const routes = [

   {
      path: '/',
      name: 'home',
      component: HomePage,
   },
   {
      path: '/page',
      name: 'page',
      component: StaticPage,
   },

   {
      path: '/profile',
      name: 'profile',
      component: ProfilePage,
   },
   {
      path: '/my-addreses',
      name: 'myAddresses',
      component: AddressesPage,
   },

   {
      path: '/basket',
      name: 'basket',
      component: BasketPage,
   },

   {
      path: '/product/:id',
      name: 'product',
      component: ProductPage,
   },


];

const router = createRouter({
   routes,
   history: createWebHistory()
});


// router.beforeEach((to, from, next) => {


//    // console.log(authService.isSurveys());

//    // if(to.name === 'cabinet' && !authService.isLoggedIn()){
//    //    next({name: 'login'});
//    // } 
//    // else if(to.name === 'login' && authService.isLoggedIn()) {
//    //    next({name: 'cabinet'});
//    // } 
//    // if(to.name === 'hello' && authService.isSurveys()) {
//    //    console.log(authService.isSurveys());
//    //    next({name: 'home'});
//    // }
//    // else {
//    //    next();
//    // }

//    next();


//  })

 export default router;