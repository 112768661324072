<template lang="">
    <div class="page">
        <h1>Статическая страница</h1>

        <p>Настоящее Соглашение заключается между индивидуальный предприниматель ИП&nbsp;Каспор Николай Андреевич (ИНН 636205134795, ОГРНИП 313633019800069) (далее&nbsp;— Владелец) и&nbsp;любым лицом, становящимся после принятия условий данного соглашения пользователем сайта, расположенного в&nbsp;сети Интернет по&nbsp;адресу: <a href="https://chatfood.ru" target="_blank">https://chatfood.ru</a> (далее&nbsp;— Сайт), в&nbsp;дальнейшем именуемым «Пользователь», вместе по&nbsp;тексту Соглашения именуемые «Стороны», а&nbsp;по&nbsp;отдельности&nbsp;— «Сторона».</p>
        <p>В&nbsp;соответствии со&nbsp;статьей 435 Гражданского кодекса&nbsp;РФ настоящее Пользовательское соглашение признается офертой.</p>
        <p>В&nbsp;соответствии со&nbsp;статьей 438 Гражданского кодекса&nbsp;РФ безусловным принятием (акцептом) условий настоящего Соглашения считается факт регистрации на&nbsp;Сайте.</p>
        <p>Настоящее Соглашение, заключаемое путем акцепта настоящей оферты, не&nbsp;требует двустороннего подписания и&nbsp;действительно в&nbsp;электронном виде.</p>
        

    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped>
    .page {
        margin: 5px 10px;
    }

    .page h1 {
        font-size: 18px;
    }
</style>