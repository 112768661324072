<template lang="">
    <div class="basket">

        <div class="item">
            <div class="image">
                <picture>
                    <img src="https://chatfood.ru/web/uploads/2022/09/khtcyS9fBMyVkafmbhLS4QzWXXH2K_.jpeg">
                </picture>
            </div>
            <div class="info">
                <div class="title">
                    Пицца пеперони классическая
                </div>
                <div class="property">
                    <div class="property-item">
                        <span>Размер: </span> Большая
                    </div>
                    <div class="property-item">
                        <span>Тесто: </span> Традиционное
                    </div>
                    <div class="property-item">
                        <span>Опции: </span> Соус чили, Ломтик бекона (+3)
                    </div>
                </div>
            </div>
            <div class="manage">
                3
            </div>
        </div>

        <button class="btn btn-destructive">Очистить корзину</button>

    </div>
</template>
<script>
export default {
    
    mounted() {
        const self = this;
        window.Telegram.WebApp.BackButton.show();
        window.Telegram.WebApp.BackButton.onClick(function() {
            self.$router.push({name: 'home'})
        });


        window.Telegram.WebApp.MainButton.show();
        window.Telegram.WebApp.MainButton.text = 'Оформить заказ';
    },
}
</script>
<style scoped>
    .basket {
        margin: 10px;
    }

    .basket .item {
        display: flex;
        justify-content: center;
    }

    .basket .item>div {
       
    }

    .basket .item .image {
        width: 50px;
        margin-right: 15px;
    }


    .basket .item .image picture {
        display: block;
        width: 100%;
        height: 0;
        padding-top: 100%; /* Создает квадратное соотношение сторон */
        overflow: hidden; /* Обрезает изображение до размеров родителя */
        position: relative; /* Для позиционирования img */
    }

    .basket .item .image picture img {
        position: absolute; /* Позволяет изображению занимать всю площадь родителя */
        width: 100%;
        height: 100%;
        object-fit: cover; /* Заполняет родителя, сохраняя соотношение сторон */
        top: 0;
        left: 0;
        border-radius: 10px; /* Закругление углов */
    }


    .basket .item .info .title {
        padding-bottom: 4px;
    }

    .basket .item .info .property {
        color: var(--tg-theme-hint-color);
        line-height: 18px;
        font-size:12px;
    }


    

    .basket .item .manage {
        width: 100px;
    }


    


</style>