<template lang="">
    <button>
        Кнопка
    </button>
    
</template>
<script>
export default {
   name: 'MainButton', 
}
</script>
<style scoped>
    button {
        background: var(--tg-theme-button-color);
        color: var(--tg-theme-button-text-color);
        border:none;
        font-weight: bold;
        width: 100%;
        line-height:40px;
        border-radius: 10px;
    }
</style>