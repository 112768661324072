import MainButton from "@/components/MainButton";
import MainNav from "@/components/MainNav";
import MainGrid from "@/components/MainGrid";
import InputForm from "@/components/InputForm";
import MainList from "@/components/MainList";
import ProductCard from "@/components/ProductCard";
import TopBar from "@/components/TopBar";


export default [
    MainButton,
    MainNav,
    MainGrid,
    InputForm,
    MainList,
    ProductCard,
    TopBar
]