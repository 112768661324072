<template lang="">
    <div class="top_bar">
        
        <div class="top_bar-reception">Самовывоз</div>
        <div class="top_bar-search">search</div>
    </div>
</template>
<script>
export default {
    name: 'TopBar',
}
</script>
<style scoped>
    .top_bar {
        display: flex;
        justify-content: center;
    }

    .top_bar>div {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 1000px;
        margin: 0 auto;
        padding: 0 10px;
        height: 26px;
        font-size: 12px;
    }


    .top_bar-reception {
        background: var(--tg-theme-secondary-bg-color);
        border-radius: 20px;
    }
</style>