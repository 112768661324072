import {createStore} from "vuex";

export default createStore({
    state: {
        basketQuantity: 0,
    },

    getters: {
        getBasketQuantity: state => state.basketQuantity
    },

    mutations: {
        setBasketQuantity(state, newValue) {
            state.basketQuantity = newValue;
        }
    },

    actions: {

    }
})