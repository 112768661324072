<template lang="">
    <div class="main_grid">
        <div class="main_grid-column">
            1
        </div>
    </div>
</template>
<script>
export default {
    name: 'MainGrid',
}
</script>
<style scoped>
    .main_grid {
        display:flex;
    }

    .main_grid-column {
        background: #ccc;
    }

    
</style>